import * as React from "react";

import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout seo={{ title: "Home" }}>
      <div className="p-6 prose dark:prose-dark prose-sm sm:prose lg:prose mx-auto">
        <p className="lead">
          Hi! My name is Tobias Scharikow. I am a fan of lighthouses. This is
          the MVP of my future website. I will use it to build my project in
          public. I will update the website features accordingly. Awesome stuff
          is coming soon. :)
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage;
